<template>
  <div class="calls">
    <Loading :active="loading" :fullscreen="true" />

    <CallReviewSidebar
      :action_id="selectedConversionAction"
      :active="callReviewActive"
      @dismiss="callReviewActive = false"
      @review-saved="callReviewActive = false"
    ></CallReviewSidebar>

    <div class="hero hero--activate">
      <div class="container form-control">
        <h2>Calls</h2>
        <div class="select-wrapper">
          <div class="select">
            <date-range-picker
              ref="picker"
              :locale-data="{ firstDay: 1, format: 'mm-dd-yyyy' }"
              :minDate="minDate"
              :autoApply="false"
              v-model="dateRange"
              @update="onUpdate"
            >
              <template v-slot:input="picker" style="min-width: 350px">
                {{ picker.startDate | date }} - {{ picker.endDate | date }}
              </template>
              <template #date="data">
                <span class="small">{{ data.date | dateCell }}</span>
                <template v-if="hasTrackingEvent(data.date)">
                  <span class="marker"> &bull; </span>
                  <span class="tooltip">{{
                    trackingEventLabel(data.date)
                  }}</span>
                </template>
              </template>
            </date-range-picker>
          </div>
          <div class="select">
            <select v-model="selectedCampaignId" @change="onUpdate">
              <option value="">All Campaigns</option>
              <option
                v-for="campaign in campaignList"
                :key="`campaign-${campaign.id}`"
                :value="campaign.id"
              >
                {{ campaign.name }}
              </option>
            </select>
          </div>
          <div class="select">
            <select v-model="selectedProviderId" @change="onUpdate">
              <option value="">All Providers</option>
              <option
                v-for="provider in providerList"
                :key="`provider-${provider.id}`"
                :value="provider.id"
              >
                {{ provider.name }}
              </option>
            </select>
          </div>
          <div class="select">
            <select v-model="selectedNurseId" @change="onUpdate">
              <option value="">All Nurses</option>
              <option
                v-for="nurse in nurseOptions"
                :key="`nurse-${nurse.id}`"
                :value="nurse.id"
              >
                {{ nurse.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="overview-wrapper">
        <div class="card-wrapper">
          <div class="card">
            <h2>Call Review Status</h2>
            <div class="lead-charts">
              <div class="doughnut">
                <div class="doughnut__wrapper">
                  <DoughnutChart
                    class="doughnut__chart"
                    :chartData="pieData"
                  ></DoughnutChart>
                  <div class="doughnut__total">
                    <strong>{{ $_conversions_calls.length }}</strong>
                    Total Calls
                  </div>
                </div>
                <div class="doughnut__legend">
                  <div class="doughnut__legend-item">
                    <span
                      class="doughnut__bullet doughnut__bullet--qualified"
                    ></span>
                    <span>Poor</span>
                    <span class="doughnut__legend-item-stat">{{
                      $_calls_totalPoor
                    }}</span>
                  </div>

                  <div class="doughnut__legend-item">
                    <span
                      class="doughnut__bullet doughnut__bullet--unqualified"
                    ></span>
                    <span>Fair</span>
                    <span class="doughnut__legend-item-stat">{{
                      $_calls_totalFair
                    }}</span>
                  </div>

                  <div class="doughnut__legend-item">
                    <span
                      class="doughnut__bullet doughnut__bullet--invalid"
                    ></span>
                    <span>Good</span>
                    <span class="doughnut__legend-item-stat">{{
                      $_calls_totalGood
                    }}</span>
                  </div>

                  <div class="doughnut__legend-item">
                    <span
                      class="doughnut__bullet doughnut__bullet--unknown"
                    ></span>
                    <span>Pending</span>
                    <span class="doughnut__legend-item-stat">{{
                      $_calls_totalPending
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="lead-metrics">
                <div class="lead-metrics__item">
                  <div class="lead-metrics__title">Average Duration</div>
                  <div class="lead-metrics__number">
                    {{ $_calls_averageDuration }}
                  </div>
                  <div class="lead-metrics__percent">min</div>
                </div>

                <div class="lead-metrics__item">
                  <div class="lead-metrics__title">Average Score</div>
                  <div class="lead-metrics__number">
                    {{ $_calls_averageScore }}
                  </div>
                  <div class="lead-metrics__percent">
                    {{ $_calls_averageScoreContext }}
                  </div>
                </div>

                <div class="lead-metrics__item">
                  <div class="lead-metrics__title">Most Calls</div>
                  <div class="lead-metrics__number lead-metrics__number--small">
                    {{ $_calls_mostCallsName }}
                  </div>
                  <div class="lead-metrics__percent">
                    {{ $_calls_mostCallsTotal }}
                  </div>
                </div>

                <div class="lead-metrics__item">
                  <div class="lead-metrics__title">Fewest Calls</div>
                  <div class="lead-metrics__number lead-metrics__number--small">
                    {{ $_calls_fewestCallsName }}
                  </div>
                  <div class="lead-metrics__percent">
                    {{ $_calls_fewestCallsTotal }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-wrapper">
          <div class="card form-control">
            <h2>Call Activity</h2>
            <LineChart
              :height="220"
              :chart-data="chartData"
              :scales="scales"
              :tooltips="tooltips"
            />
            <div class="graph-legend">
              <div class="graph-legend-item">
                <span
                  class="graph-legend-item-indicator"
                  :style="{ background: this.colors.lightBlueTint2 }"
                ></span>
                <span class="graph-legend-item-label">Total Calls</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="conversions-title">
        <h2>Call Details</h2>

        <div class="filterbar">
          <span
            v-if="filter !== '' || filterDispositions.length || filterReviews"
            @click="onClearFilter"
            class="filterbar__clear"
            >Clear</span
          >
          <div class="filterbar__input">
            <input
              type="text"
              v-model="filter"
              v-on:keyup.enter="onFilterCalls"
              placeholder="Search calls"
            />
          </div>
          <div
            class="filterbar__select"
            v-click-outside="onCloseDispositionsFilterbar"
          >
            <div @click="toggleDispositions" class="filterbar__label">
              Dispositions
              <Pill :count="filterDispositions.length" />
            </div>
            <div v-if="dispositionsActive" class="filterbar__checkboxes">
              <div>
                <input
                  type="checkbox"
                  value="Pending"
                  v-model="filterDispositions"
                />
                Pending
              </div>
              <div v-for="(event, index) in events" :key="`event-${index}`">
                <input
                  type="checkbox"
                  :value="event.external_name"
                  v-model="filterDispositions"
                />
                {{ event.external_name }}
              </div>
            </div>
          </div>
          <div class="filterbar__select form-control">
            <select v-model="filterReviews">
              <option :value="null">All Reviews</option>
              <option value="complete">Reviews Complete</option>
              <option value="poor">Poor Score</option>
              <option value="fair">Fair Score</option>
              <option value="good">Good Score</option>
              <option value="pending">Reviews Pending</option>
            </select>
          </div>
          <div class="filterbar__submit">
            <button @click="onFilterCalls">Filter</button>
          </div>
        </div>

        <div class="list-actions">
          <button class="button" @click="onDownloadList">Download List</button>
        </div>
      </div>

      <div class="card-wrapper">
        <div class="card form-control">
          <template v-if="$_conversions_calls.length">
            <table class="styled-table">
              <tr>
                <th @click="sortCalls('nurse_id')">Nurse</th>
                <th @click="sortCalls('call_direction')">Direction</th>
                <th @click="sortCalls('name')">Called</th>
                <th @click="sortCalls('date')">Date</th>
                <th width="250">Recording</th>
                <th @click="sortCalls('disposition')">Disposition</th>
                <th class="icon-cell" @click="sortCalls('reviewed')">Review</th>
              </tr>
              <tr v-for="call in filteredCalls" :key="`call-${call.id}`">
                <td>
                  <strong>
                    {{
                      $_nurses_getNurseNameById(call.data.nurse_id) | nurseCell
                    }}
                  </strong>
                </td>
                <td>
                  <Pill
                    v-if="call.call_direction === 'inbound'"
                    :count="`← ${call.call_direction}`"
                    :label="true"
                  />
                  <Pill
                    v-if="call.call_direction === 'outbound'"
                    :count="`${call.call_direction} →`"
                    :label="true"
                  />
                </td>
                <td @click="onSelectConversion(call.conversion.id)">
                  <strong>{{
                    $_conversions_getFullName(call.conversion) | valueOrDash
                  }}</strong>
                  {{ call.conversion.phone }}
                </td>
                <td>{{ call.date | date }}</td>
                <td>
                  <audio
                    v-for="recording in call.recordings"
                    :key="`recording-${recording.id}`"
                    controls="controls"
                    :src="recording.url"
                  ></audio>
                </td>
                <td>{{ call.disposition | dispositionCell }}</td>
                <td
                  @click="openReviewSidebar(call.id)"
                  class="icon-cell"
                  :class="getScoreContext(call.review)"
                >
                  <template
                    v-if="
                      call.reviewed === true &&
                      getReviewEmpty(call.review) === true
                    "
                  >
                    <Pill count="Skipped" :label="true" />
                  </template>
                  <template v-else>
                    <template v-if="call.reviewed === true">
                      <span class="icon-cell__score">
                        <Pill
                          :count="getTotalScore(call.review)"
                          :label="true"
                        />
                      </span>
                    </template>
                    <Pill
                      v-else-if="call.reviewed === false"
                      count="Pending"
                      :alert="true"
                      :label="true"
                    />
                  </template>
                </td>
              </tr>
            </table>

            <Pagination
              :items="calls"
              :pageLength="pageLength"
              @paginate="onPaginate"
            ></Pagination>
          </template>
          <template v-else>
            <p class="alert">No Calls</p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import numeral from "numeral";
import Loading from "@/components/Loading";
import { conversions } from "@/mixins/conversions";
import { colors } from "@/mixins/colors";
import Pagination from "@/components/Pagination";
import DoughnutChart from "@/components/charts/DoughnutChart";
import LineChart from "@/components/charts/LineChart";
import dateFormat from "date-fns/format";
import DateRangePicker from "vue2-daterange-picker";
import { events } from "@/mixins/events";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Papa from "papaparse";
import FileDownload from "js-file-download";
import { nurses } from "@/mixins/nurses";
import { callReview } from "@/mixins/callReview";
import Pill from "@/components/Pill";
import CallReviewSidebar from "@/components/CallReviewSidebar";

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

const today = new Date();
const oneWeekAgo = new Date();
oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

export default {
  name: "Calls",
  mixins: [conversions, colors, events, nurses, callReview],
  components: {
    Loading,
    Pagination,
    DoughnutChart,
    LineChart,
    DateRangePicker,
    Pill,
    CallReviewSidebar,
  },
  data() {
    return {
      createNewConversionActive: false,
      minDate: "08-31-2021",
      newConversion: null,
      pageLength: 20,
      sidebarActive: false,
      selectedConversion: null,
      selectedConversionAction: null,
      selectedCampaignId: "",
      selectedNurseId: "",
      selectedProviderId: "",
      dateRange: {
        startDate: oneWeekAgo,
        endDate: today,
      },
      conversions: [],
      filteredConversions: [],
      calls: [],
      filteredCalls: [],
      filter: "",
      filterDispositions: [],
      dispositionsActive: false,
      reviewStatusActive: false,
      filterReviews: null,
      sortDirection: "asc",
      sortFlag: null,
      callReviewActive: false,
    };
  },
  filters: {
    dateCell(value) {
      let dt = new Date(value);

      return dt.getDate();
    },
    dispositionCell(value) {
      return value.replace("No Transfer", "NT");
    },
    nurseCell(value) {
      if (typeof value == "undefined" || value === null) {
        return "-";
      }

      let name_parts = value.split(" ");
      if (name_parts.length === 2) {
        name_parts[1] = name_parts[1].slice(0, 1) + ".";
      }

      return name_parts.join(" ");
    },
  },
  computed: {
    loading() {
      return this.$store.getters["conversions/loading"];
    },
    campaignList() {
      return this.$store.getters["conversions/campaigns"];
    },
    providerList() {
      return this.$store.getters["conversions/providers"];
    },
    trackingEvents() {
      return this.$store.getters["conversions/tracking_events"];
    },
    dateRangeToNumber() {
      let num = 0;
      switch (this.dateRange) {
        case "year":
          num = 365;
          break;
        case "month":
          num = 30;
          break;
        case "week":
          num = 7;
          break;
        case "day":
          num = 2;
          break;
      }

      return num;
    },
    dates() {
      const startDate = new Date(this.dateRange.startDate);
      const endDate = new Date(this.dateRange.endDate);

      var results = new Array();
      var currentDate = startDate;
      while (currentDate <= endDate) {
        results.push(new Date(currentDate));
        currentDate = currentDate.addDays(1);
      }

      return results;
    },
    datesLabels() {
      let result = [];
      this.dates.forEach((date) => {
        result.push(`${date.getMonth() + 1}/${date.getDate()}`);
      });

      return result;
    },
    actionsByDate() {
      return this.callsByDate(this.$_conversions_calls);
    },
    scales() {
      return {
        yAxes: [
          {
            ticks: {
              suggestedMin: 0,
              callback: function (value) {
                return numeral(value).format("0,0");
              },
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              callback: (value) => {
                if (this.dateRange === "year") {
                  return dateFormat(value, "MMM");
                }

                return `${value.getMonth() + 1}/${value.getDate()}`;
              },
            },
          },
        ],
      };
    },
    tooltips() {
      return {
        callbacks: {
          label: function (tooltipItem) {
            return numeral(tooltipItem.value).format("0,0");
          },
          title: function (tooltipItem) {
            let d = new Date(tooltipItem[0].xLabel);
            return dateFormat(d, "M/d/y");
          },
        },
      };
    },
    chartData() {
      return {
        labels: this.dates,
        datasets: [
          {
            backgroundColor: this.colors.lightBlueTint2,
            borderColor: this.colors.lightBlueTint2,
            data: this.actionsByDate,
            fill: false,
            lineTension: 0.5,
          },
        ],
      };
    },
    pieData: function () {
      return {
        labels: ["Poor", "Fair", "Good", "Pending", "Other"],
        datasets: [
          {
            data: [
              this.$_calls_totalPoor,
              this.$_calls_totalFair,
              this.$_calls_totalGood,
              this.$_calls_totalPending,
              this.$_calls_totalOther,
            ],
            backgroundColor: [
              this.colors.lightBlueTint0,
              this.colors.lightBlueTint1,
              this.colors.lightBlueTint2,
              this.colors.lightBlueTint3,
              this.colors.fadeGray,
            ],
            borderWidth: 0,
          },
        ],
      };
    },
    nurseOptions: function () {
      return this.$_nurses_getOptions();
    },
    $_calls_totalPoor: function () {
      return this.getTotalByScore("poor");
    },
    $_calls_totalFair: function () {
      return this.getTotalByScore("fair");
    },
    $_calls_totalGood: function () {
      return this.getTotalByScore("good");
    },
    $_calls_totalPending: function () {
      return this.getTotalByReviewed(false);
    },
    $_calls_totalOther: function () {
      return (
        this.$_conversions_calls.length -
        this.$_calls_totalPoor -
        this.$_calls_totalFair -
        this.$_calls_totalGood -
        this.$_calls_totalPending
      );
    },
    $_calls_averageDuration: function () {
      const call_durations = [];

      //Get all recording durations
      this.$_conversions_calls.forEach(function (call) {
        call.recordings.forEach(function (recording) {
          if (recording.duration && recording.duration > 0) {
            call_durations.push(recording.duration);
          }
        });
      });

      const duration_average =
        call_durations.reduce((total, duration) => {
          return total + duration;
        }, 0) / call_durations.length;

      if (isNaN(duration_average)) {
        return "";
      }

      return Math.round(duration_average / 60); //Convert seconds to minutes
    },
    $_calls_averageScore: function () {
      const getTotalScore = this.getTotalScore;
      const getReviewEmpty = this.getReviewEmpty;

      const reviewed_calls = this.$_conversions_calls.filter(function (call) {
        return call.reviewed === true && getReviewEmpty(call.review) === false;
      });

      const score_average =
        reviewed_calls.reduce((total, call) => {
          return total + getTotalScore(call.review);
        }, 0) / reviewed_calls.length;

      if (isNaN(score_average)) {
        return "";
      }

      return Math.round(score_average);
    },
    $_calls_averageScoreContext: function () {
      const score_average = this.$_calls_averageScore;
      return this.getScoreContextRaw(score_average);
    },
    $_calls_mostCallsTotal: function () {
      const nurses = this.getNurseCallCount();

      return Math.max(...Object.values(nurses));
    },
    $_calls_mostCallsName: function () {
      const nurses = this.getNurseCallCount();

      const nurse = Object.keys(nurses).reduce((a, b) =>
        nurses[a] > nurses[b] ? a : b
      );

      return this.$_nurses_getNurseNameById(nurse);
    },
    $_calls_fewestCallsTotal: function () {
      const nurses = this.getNurseCallCount();

      return Math.min(...Object.values(nurses));
    },
    $_calls_fewestCallsName: function () {
      const nurses = this.getNurseCallCount();

      const nurse = Object.keys(nurses).reduce((a, b) =>
        nurses[a] < nurses[b] ? a : b
      );

      return this.$_nurses_getNurseNameById(nurse);
    },
  },
  methods: {
    routeIsUpToDate(query) {
      const keys1 = Object.keys(query);
      const keys2 = Object.keys(this.$route.query);

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (let key of keys1) {
        if (query[key] !== this.$route.query[key]) {
          return false;
        }
      }

      return true;
    },
    onUpdate() {
      const dateRangeString = JSON.stringify(this.dateRange);

      this.$_conversions_getCalls(
        this.selectedCampaignId,
        this.selectedProviderId,
        this.selectedNurseId,
        dateRangeString
      ).then(() => {
        this.calls = this.$_conversions_calls;
        this.filter = "";
        this.filterDispositions = [];
      });

      const query = {
        campaign: this.selectedCampaignId,
        provider: this.selectedProviderId,
        nurse: this.selectedNurseId,
        range: dateRangeString,
      };

      if (!this.routeIsUpToDate(query)) {
        this.$router.push({
          path: "calls",
          query: query,
        });
      }
    },
    onSelectConversion(conversionId) {
      let routeData = this.$router.resolve({
        name: "conversion",
        params: { id: conversionId },
      });
      window.open(routeData.href, "_blank");
    },
    getReportingData() {
      this.$store.dispatch("conversions/getReportingData");
    },
    onDownloadList() {
      const csv = Papa.unparse(this.formatDownloadList());
      FileDownload(csv, `sharemd-connect-calls.csv`);
    },
    formatDownloadList() {
      const $_nurses_getNurseNameById = this.$_nurses_getNurseNameById;
      const calls = this.calls.map(function (call) {
        return {
          nurse: $_nurses_getNurseNameById(call.data.nurse_id),
          first_name: call.conversion.first_name,
          last_name: call.conversion.last_name,
          date: dateFormat(new Date(call.date), "MMM d, Y - haaa"),
          recordings: call.recordings.length,
          disposition: call.disposition,
        };
      });

      return calls;
    },
    onPaginate(items) {
      this.filteredCalls = items;
    },
    onClearFilter() {
      this.filter = "";
      this.filterDispositions = [];
      this.filterReviews = null;

      this.onFilterCalls();
    },
    onFilterCalls() {
      this.dispositionsActive = false;
      this.calls = this.$_conversions_calls;
      const getScoreContext = this.getScoreContext;

      if (this.filterReviews) {
        this.calls = this.calls.filter((call) => {
          if (!this.filterReviews) {
            return true;
          }
          if (this.filterReviews === "pending") {
            return call.reviewed === false;
          } else if (this.filterReviews === "complete") {
            return call.reviewed === true;
          } else {
            return getScoreContext(call.review) === this.filterReviews;
          }
        });
      }

      if (this.filter !== "") {
        const filter = this.filter.toLowerCase();
        this.calls = this.calls.filter((call) => {
          if (call.conversion.first_name === null) {
            call.conversion.first_name = "";
            call.conversion.last_name = "";
          }
          const result =
            call.conversion.first_name.toLowerCase().indexOf(filter) !== -1 ||
            call.conversion.last_name.toLowerCase().indexOf(filter) !== -1 ||
            this.$_conversions_getFullName(call.conversion)
              .toLowerCase()
              .indexOf(filter) !== -1 ||
            call.data.nurse_id.indexOf(filter) !== -1;
          return result;
        });
      }

      if (this.filterDispositions.length) {
        this.calls = this.calls.filter((call) => {
          return this.filterDispositions.indexOf(call.data.disposition) !== -1;
        });
      }
    },
    toggleDispositions() {
      this.dispositionsActive = !this.dispositionsActive;
    },
    sortCalls(flag) {
      if (this.sortFlag === flag) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortDirection = "asc";
      }
      const sortDirection = this.sortDirection;

      function compare(a, b) {
        // Use toUpperCase() to ignore character casing

        let bandA = a[flag];
        let bandB = b[flag];

        if (typeof a[flag] === "string") {
          bandA = a[flag].toUpperCase();
          bandB = b[flag].toUpperCase();
        }

        let comparison = 0;
        if (bandA > bandB) {
          comparison = sortDirection === "asc" ? 1 : -1;
        } else if (bandA < bandB) {
          comparison = sortDirection === "asc" ? -1 : 1;
        }

        return comparison;
      }

      this.calls.sort(compare);
      this.sortFlag = flag;
    },
    callsByDate(calls, date_type) {
      if (!date_type) {
        date_type = "date";
      }
      let result = [];
      this.dates.forEach((date) => {
        date.setHours(0, 0, 0, 0);
        let sum = calls.reduce((total, engagement) => {
          let d = new Date(engagement[date_type]);
          d.setHours(0, 0, 0, 0);
          return total + (date.getTime() === d.getTime() ? 1 : 0);
        }, 0);

        result.push(sum);
      });

      return result;
    },
    getTrackingEvent(date) {
      let dt = new Date(date);
      let date_string = dt.toISOString().split("T")[0];
      let campaign_id = this.selectedCampaignId;

      return this.trackingEvents.filter(function (event) {
        let campaign_match = true;
        if (campaign_id !== "") {
          campaign_match =
            event.campaign_id == campaign_id || event.campaign_id === null;
        }
        return event.date === date_string && campaign_match;
      });
    },
    hasTrackingEvent(date) {
      return this.getTrackingEvent(date).length !== 0;
    },
    trackingEventLabel(date) {
      let events = this.getTrackingEvent(date).map(function (event) {
        return event.label;
      });
      return events.join(" | ");
    },
    onCloseDispositionsFilterbar() {
      this.dispositionsActive = false;
    },
    openReviewSidebar(id) {
      this.selectedConversionAction = id;
      this.callReviewActive = true;
    },
    getTotalByScore(score) {
      if (!score) {
        score === "good";
      }

      return this.$_conversions_calls.reduce((total, call) => {
        if (this.getScoreContext(call.review) === score) {
          return total + 1;
        }

        return total;
      }, 0);
    },
    getTotalByReviewed(reviewed) {
      return this.$_conversions_calls.reduce((total, call) => {
        if (call.reviewed === reviewed) {
          return total + 1;
        }

        return total;
      }, 0);
    },
    getNurseCallCount() {
      const nurses = {};

      this.$_conversions_calls.forEach(function (call) {
        if (!call.nurse_id) {
          return;
        }
        if (!Object.prototype.hasOwnProperty.call(nurses, call.nurse_id)) {
          nurses[call.nurse_id] = 0;
        }

        nurses[call.nurse_id]++;
      });

      return nurses;
    },
  },
  mounted() {
    this.getReportingData();

    if (
      typeof this.$route.query.campaign !== "undefined" &&
      this.$route.query.campaign !== ""
    ) {
      this.selectedCampaignId = this.$route.query.campaign;
    }

    if (
      typeof this.$route.query.provider !== "undefined" &&
      this.$route.query.provider !== ""
    ) {
      this.selectedProviderId = this.$route.query.provider;
    }

    if (
      typeof this.$route.query.nurse !== "undefined" &&
      this.$route.query.nurse !== ""
    ) {
      this.selectedNurseId = this.$route.query.nurse;
    }

    if (typeof this.$route.query.range !== "undefined") {
      const range = JSON.parse(this.$route.query.range);
      this.dateRange = {
        endDate: new Date(range.endDate),
        startDate: new Date(range.startDate),
      };
    }

    this.onUpdate();
  },
  watch: {
    $route() {
      if (typeof this.$route.query.campaign === "undefined") {
        this.getCampaigns();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";
@import "@/scss/button";
@import "@/scss/forms";
@import "@/scss/table";
@import "@/scss/hero";
@import "@/scss/filter";

.lead-charts {
  display: flex;
}

.lead-metrics {
  flex-grow: 1;

  &__item {
    align-items: center;
    border-bottom: 1px solid $light-gray;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
    padding: 8px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    flex-grow: 1;
  }

  &__number {
    font-size: 32px;
    font-weight: 600;
    text-align: right;
    width: 75px;

    &--small {
      font-size: 18px;
    }
  }

  &__percent {
    color: $light-gray;
    font-size: 12px;
    text-align: right;
    width: 40px;
  }
}

.doughnut {
  margin: 0 3em 2em 0;
  width: 205px;

  &__wrapper {
    position: relative;
  }

  &__chart {
    position: relative;
    z-index: 1;
  }

  &__total {
    font-size: 16px;
    left: 50%;
    line-height: 1.2;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;

    strong {
      display: block;
      font-size: 36px;
    }
  }

  &__legend {
    margin: 1rem auto 0 0;
  }

  &__legend-item {
    font-size: 14px;
    margin-bottom: 6px;
    display: flex;
    align-items: center;

    &-stat {
      margin-left: auto;
      font-weight: 700;
    }
  }

  &__bullet {
    display: inline-block;
    height: 8px;
    border-radius: 50%;
    margin-right: 6px;
    width: 8px;

    &--qualified {
      background-color: $light-blue-tint0;
    }

    &--unqualified {
      background-color: $light-blue-tint1;
    }

    &--pending {
      background-color: $orange;
    }

    &--won {
      background-color: $green;
    }

    &--invalid {
      background-color: $light-blue-tint2;
    }

    &--unknown {
      background-color: $light-blue-tint3;
    }
  }
}

.daterangepicker {
  color: #000;

  td {
    position: relative;

    &:hover {
      z-index: 10;
    }
  }
  .marker {
    bottom: 4px;
    color: $dark-blue;
    left: 50%;
    line-height: 4px;
    margin-left: -2px;
    position: absolute;
  }

  .active .marker {
    color: #fff;
  }

  .tooltip {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    color: $dark-blue;
    display: none;
    left: 50%;
    padding: 4px;
    position: absolute;
    top: 110%;
    transform: translateX(-50%);
    white-space: normal;
    width: 200px;
    z-index: 9;
  }

  td:hover .tooltip {
    display: block;
  }
}

.calls .hero {
  .select {
    max-width: 200px;
  }
  .select .vue-daterange-picker {
    .reportrange-text {
      height: 30px;
      padding-top: 8px;
      margin-bottom: 0;
    }

    .daterangepicker .btn-primary,
    .daterangepicker .btn-success {
      background-color: $light-blue;
    }
  }
}

.create-conversion-modal {
  p {
    font-size: 14px;
    line-height: 1.2;
    margin-bottom: 2em;
  }
}

.conversions-title {
  align-content: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
  position: relative;
  z-index: 9;

  h2 {
    margin-bottom: 0 !important;
  }

  .list-actions {
    button + button {
      margin-left: 8px;
    }
  }
}

.calls {
  .overview-wrapper {
    margin-bottom: 2rem;
    display: flex;
    position: relative;

    .card-wrapper:first-of-type {
      width: 50%;
      padding-right: 1rem;
    }

    .card-wrapper:last-of-type {
      width: 50%;
      padding-left: 0.5rem;

      .card {
        height: 100%;
      }
    }

    .graph-legend {
      display: flex;
      justify-content: center;
      margin-top: 1rem;

      &-item {
        display: flex;
        align-items: center;
        margin: 0 1rem;

        &-indicator {
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
        }

        &-label {
          font-size: 0.75rem;
          color: $medium-gray;
          margin-left: 0.25rem;
        }
      }

      &.graph-legend-top {
        margin-top: 0;
        margin-bottom: 1rem;
        justify-content: flex-start;

        .graph-legend-item {
          margin: 0;

          &-label {
            margin-left: 0;
          }
        }
      }
    }
  }

  .hero {
    .container {
      .select-wrapper {
        margin-left: auto;
        display: flex;

        .select {
          margin-left: 32px;
          select {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  li {
    margin: 8px 0;
  }

  .card {
    position: relative;

    .select-wrapper {
      position: absolute;
      top: 1.25rem;
      right: 2rem;

      .select {
        display: inline-block;
        margin-right: 1rem;
        min-width: 128px;

        &:last-of-type {
          margin-right: 0;
        }
      }

      & + .styled-table {
        margin-top: 48px;
      }
    }
  }

  .styled-table {
    margin-bottom: 0;
    tr {
      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        background-color: #f6f6f6;

        &:first-child {
          background-color: #fff;
        }
      }
    }

    th,
    td {
      padding: 1rem 1.5rem;
      &:nth-child(2) {
        padding: 1rem 0;
        width: 120px;
      }
    }

    th {
      cursor: pointer;
    }

    td {
      padding-bottom: 8px;
      padding-top: 8px;
      vertical-align: middle;

      &:last-of-type {
        width: 120px;
      }

      strong {
        color: $light-blue;
        display: block;
        font-size: 16px;
        margin-bottom: 4px;
      }

      audio {
        width: 250px;
      }
    }

    span {
      cursor: pointer;
    }

    .icon-cell {
      text-align: center;

      svg {
        fill: $green;
      }

      &.poor {
        .icon-cell__score {
          .pill {
            background-color: $dark-red;
            color: #fff;
          }
        }
      }

      &.fair {
        .icon-cell__score {
          .pill {
            background-color: $yellow;
            color: #fff;
          }
        }
      }

      &.good {
        .icon-cell__score {
          .pill {
            background-color: $green;
            color: #fff;
          }
        }
      }
    }
  }

  .kpi-wrapper {
    display: flex;
    margin: 0 -8px;
    margin-bottom: 2rem;

    .card {
      margin: 0 8px;
      width: 25%;
    }
  }
}

.form-row {
  flex-grow: 1;
  margin-bottom: 1em;

  label {
    display: block;
    margin-bottom: 6px;
  }

  textarea {
    height: 44px;
  }
}
</style>